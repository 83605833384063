<template>
  <v-card
    flat
  >
    <v-card-title>{{ title }}</v-card-title>

    <v-card-actions
      dense
      flat
      class="mt-2"
    >
      <v-spacer />
      <v-responsive max-width="260">
        <v-text-field
          v-model="search"
          class="mx-2"
          append-icon="mdi-magnify"
          color="pink"
          label="Search"
          dense
          flat
          hide-details
          rounded
          filled
          single-line
        />
      </v-responsive>
      <v-btn
        class="mx-2 text-capitalize"
        color="pink"
        dark
        :to="buttonTo"
        @click="$emit('create')"
      >
        {{ buttonText }}
      </v-btn>
    </v-card-actions>

    <v-data-table
      v-bind="$attrs"
      :search="search"
      :sort-by="sortBy"
      v-on="$listeners"
    >
      <template
        v-for="(_, slot) of $scopedSlots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'CrudCardTable',
  props: {
    title: {
      type: String,
      default: ''
    },
    sortBy: {
      type: String,
      default: 'createdAt'
    },
    buttonText: {
      type: String,
      default: 'New'
    },
    buttonTo: {
      type: [String, Object],
      default: undefined
    }
  },
  data () {
    return {
      search: null
    }
  }
}
</script>

<style scoped>

</style>
